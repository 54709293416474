import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login';
import Index from '../views/Index';
import User from '../views/sys/User';
import Role from '../views/sys/Role';
import Dict from '../views/sys/Dict';
import Menu from '../views/sys/Menu';
import Home from "../views/Home";
import el from "element-ui/src/locale/lang/el";
import axios from "../axios";
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/index',
        name: 'Index',
        meta: {
          title: "首页"
        },
        component: Index
      },
      {
        path: '/reg/prizeStatistics',
        name: 'prizeStatistics',
        meta: {
          title: "获奖数据导入"
        },
        component: () => import('@/views/reg/PrizeStatistics.vue')
      },
      {
        path: '/reg/workPerson',
        name: 'workPerson',
        meta: {
          title: "个人日程"
        },
        component: () => import('@/views/reg/PrizeStatistics.vue')
      },
      {
        path: '/userCenter',
        name: 'UserCenter',
        meta: {
          title: "个人中心"
        },
        component: () => import('@/views/UserCenter.vue')
      },
      {
        path: '/pro/leave',
        name: 'Leave',
        meta: {
          title: "请假表单"
        },
        component: () => import('@/views/pro/Leave.vue')
      },
      {
        path: '/pro/overtime',
        name: 'Overtime',
        meta: {
          title: "加班表单"
        },
        component: () => import('@/views/pro/Overtime.vue')
      },
      {
        path: '/pro/purchase',
        name: 'Purchase',
        meta: {
          title: "采购表单"
        },
        component: () => import('@/views/pro/Purchase.vue')
      },
      {
        path: '/pro/business',
        name: 'Business',
        meta: {
          title: "出差表单"
        },
        component: () => import('@/views/pro/Business.vue')
      },
      {
        path: '/pro/car',
        name: 'Car',
        meta: {
          title: "用车申请单"
        },
        component: () => import('@/views/pro/Car.vue')
      },
      {
        path: '/pro/reception',
        name: 'Reception',
        meta: {
          title: "公务接待单"
        },
        component: () => import('@/views/pro/Reception.vue')
      },
      {
        path: '/pro/guest',
        name: 'Guest',
        meta: {
          title: "来宾入住通知单"
        },
        component: () => import('@/views/pro/Guest.vue')
      },
      {
        path: '/pro/dine',
        name: 'Dine',
        meta: {
          title: "食堂支出采购单"
        },
        component: () => import('@/views/pro/Dine.vue')
      },
      {
        path: '/pro/dineRepair',
        name: 'DineRepair',
        meta: {
          title: "食堂支出维修单"
        },
        component: () => import('@/views/pro/DineRepair.vue')
      },
      {
        path: '/pro/dineOvertime',
        name: 'DineOvertime',
        meta: {
          title: "食堂加班表单"
        },
        component: () => import('@/views/pro/DineOvertime.vue')
      },
      {
        path: '/pro/contract',
        name: 'Contract',
        meta: {
          title: "合同订立审批单"
        },
        component: () => import('@/views/pro/Contract.vue')
      },

    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let hasRoutes = store.state.menus.hasRoutes
  console.log("hasRoutes")
  console.log(hasRoutes)
  console.log("from")
  console.log(from.path)
  console.log("to")
  console.log(to.path)
  let menus = store.state.menus.menuList
  let token = localStorage.getItem("token")
  if(to.path == '/'){
    next({path:'/index'})
  }

  if (to.path == '/login') {
    console.log("login!!!!!!!!!!!")
    next()
  }else if(!token){
    next({path:'/login'})
  }else if (token && !hasRoutes) {
  // }else if (!hasRoutes) {
    let newRoutes = router.options.routes;
    axios.get("/sys/menu/nav", {headers:{
        Authorization: localStorage.getItem("token")
      }}).then(res => {
    // axios.get("/sys/menu/nav").then(res => {
      console.log(res.data.data)
      store.commit("setMenuList", res.data.data.nav)
      store.commit("setPermList", res.data.data.authoritys)
      res.data.data.nav.forEach(menu => {
        if (menu.children) {
          menu.children.forEach(e => {
            let route = menuToRoute(e)
            if(route) {
              newRoutes[0].children.push(route)
            }
          })
        }
      })
      console.log("oldRoutes---------------")
      console.log(newRoutes)
      router.addRoutes(newRoutes)
      store.commit("changeRouteStatus", true)
      next({path: to.path})
    })
  } else {
    console.log("已经有路由了------------")
    console.log("我来看下to")
    console.log(to)
    console.log("我来看下router")
    console.log(router.getRoutes())
    console.log("我来看下现在的router")
    console.log(router.currentRoute)
    next()
  }
})
const menuToRoute = (menu) => {
  console.log("正在添加menu--》")
  console.log(menu)
  if (!menu.component) {
    return null
  }
  // 复制属性
  let route = {
    name: menu.name,
    path: menu.path,
    meta: {
      icon: menu.icon,
      title: menu.title,
    }
  }
  route.component = () => import('@/views/' + menu.component +'.vue')
  return route
}
export default router



