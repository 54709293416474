import Vue from 'vue'
import Vuex from 'vuex'
import menus from "./modules/menus"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: '',
    prizeId:'',
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      if(token!=null){
        localStorage.setItem("token", token)
      }
    },
    setUserInfo(state,userInfo){
      state.userInfo=userInfo;
    },

  },
  modules: {
    menus
  }
})
