import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default {
  state: {
    // 菜单栏数据
    menuList:  [],
    // 权限数据
    permList: [],


    hasRoutes: false,

    editableTabsValue: 'Index',
    editableTabs: [{
      title: '首页',
      name: 'Index',
    }]

  },
  mutations: {
    setMenuList(state,menus){
        state.menuList=menus;
    },
    setPermList(state,perms){
        state.permList=perms;
    },
    changeRouteStatus(state,hasRoutes){
      state.hasRoutes = hasRoutes;
    },
    addTab(state, tab) {
      console.log("tab")
      console.log(tab)
      // 判断是否在栈内
      let index = state.editableTabs.findIndex(item => item.name === tab.name)
      if (index === -1) {
        // 添加到tabs中
        console.log("添加了那个tab:")
        console.log(tab)
        state.editableTabs.push(tab)
      }
      // 当前激活的tab
      console.log("我在内部执行addTab操作")
      console.log(state.editableTabs)
      state.editableTabsValue = tab.name
    },
    setActiveTab(state, tabName) {
      state.editableTabsValue = tabName
    },
    resetState: (state) => {

        console.log("我正在重置resetState")
        state.menuList =[],
        state.permList=[],

        state.hasRoutes=false,
        state.editableTabsValue='index',
        state.editableTabs=[
          {
            title: '首页',
            name: 'Index',
          }
        ]
    }

  }
}
