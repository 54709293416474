
<template>
<div class="login">


<!--  <vue-particles color="#409EFF"-->
<!--                 :particleOpacity="0.7"-->
<!--                 :particlesNumber="5"-->
<!--                 shapeType="circle"-->
<!--                 :particleSize="3"-->
<!--                 linesColor="#409EFF"-->
<!--                 :linesWidth="1"-->
<!--                 :lineLinked=true-->
<!--                 :lineOpacity="0.4"-->
<!--                 :linesDistance="600"-->
<!--                 :moveSpeed="3"-->
<!--                 :hoverEffect=true-->
<!--                 hoverMode="grab"-->
<!--                 :clickEffect=true-->
<!--                 clickMode="push"-->
<!--                 class="lizi"-->
<!--  >-->

<!--  </vue-particles>-->
  <div id="login-container">

    <el-form :inline=true :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="100px" class="demo-ruleForm">
      <div style="height: 50px;font-size: x-large;color: #17B3A3;margin-left: 40px;margin-bottom: 30px">
        舟山教育学院网上登记审批系统
      </div>
      <el-form-item label="用户名" prop="username" style="width: 380px;">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password"  style="width: 380px;">
        <el-input v-model="loginForm.password" type="password"></el-input>
      </el-form-item>
<!--      <el-form-item label="验证码" prop="code"  style="width: 380px">-->
<!--        <el-input v-model="loginForm.code" style="width: 110px;float: left"  maxlength="4"></el-input>-->
<!--        <el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>-->

<!--      </el-form-item>-->

      <el-form-item style="margin-left: 40px;margin-top: 10px">
        <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
        <el-button @click="resetForm('loginForm')">重置</el-button>
      </el-form-item>
    </el-form>



  </div>


</div>
</template>


<!--<template>-->
<!--  <el-row type="flex" class="row-bg" justify="center" id="login-container">-->
<!--&lt;!&ndash;    <div style="margin-bottom: 80px;font-size: 30px;">&ndash;&gt;-->
<!--&lt;!&ndash;      舟山教育学院oa系统&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <el-col :xl="6" :lg="7">-->

<!--      <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="80px">-->
<!--        <el-form-item label="用户名" prop="username" style="width: 380px;">-->
<!--          <el-input v-model="loginForm.username"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="密码" prop="password"  style="width: 380px;">-->
<!--          <el-input v-model="loginForm.password" type="password"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="验证码" prop="code"  style="width: 380px;">-->
<!--          <el-input v-model="loginForm.code"  style="width: 172px; float: left" maxlength="5"></el-input>-->
<!--          <el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>-->
<!--        </el-form-item>-->

<!--        <el-form-item>-->
<!--          <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>-->
<!--          <el-button @click="resetForm('loginForm')">重置</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-col>-->
<!--  </el-row>-->
<!--</template>-->

<script>
import qs from 'qs'
import Element from "element-ui";
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        code: '',
        token: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度为 4 个字符', trigger: 'blur' }
        ],
      },
      captchaImg: null
    };
  },
  methods: {
    submitForm(formName) {
      // localStorage.clear()
      // sessionStorage.clear()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/login?'+ qs.stringify(this.loginForm)).then(res => {
            console.log("登录成功")
            console.log(res)
            if(res.data.code!=200){
              Element.Message.error(res.data.msg? res.data.msg : '系统异常！', {duration: 3 * 1000})
            }else{
              const jwt = res.headers['authorization']
              this.$store.commit('SET_TOKEN', jwt)
              this.$router.push("/index")
            }

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.getCaptcha();
    },
    getCaptcha() {
      setTimeout(()=> {
        this.$axios.get('/captcha').then(res => {
          console.log("/captcha")
          console.log(res)
          localStorage.clear()
          sessionStorage.clear()
          this.$store.commit("resetState")
          this.loginForm.token = res.data.data.token
          this.captchaImg = res.data.data.captchaImg
          this.loginForm.code = ''
        })
      },500)
    }
  },
  created() {
    this.getCaptcha()
  }
}
</script>

<style scoped>

.login {
  width: 100%;
  height: 100%;
  color: #cccccc;
  /*如果想做背景图片 可以给标签一个class 直接添加背景图*/
  position: relative;
}
.el-row {
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.el-divider {
  height: 200px;
}
.captchaImg {
  /*float: left;*/
  /*margin-left: 8px;*/
  /*border-radius: 4px;*/
  /*margin-left: 8px;*/
  /*border-radius: 4px;*/
  /*display: flex;*/
}
body{
  margin: 20px;
}

#login-container{
  width: 400px;
  height: 290px;
  position: absolute;
  left: 50%;
  top: 37%;
  margin-left: -220px;
  margin-top: -140px;
  border-radius: 5px;
  padding-top: 40px;
  padding-right: 40px;
  display: flex;
  text-align: center;
  border: 3px solid #17B3A3;
}
</style>
