<template>
  <el-row :gutter="12">
    <el-col :span="3">
      <el-card shadow="always">
        <div slot="header" class="clearfix"  style="font-size: 13px;color: green">
          <span>你的表单</span>
        </div>
        <div style="text-align: center">
          {{myFormInfo.total}}
        </div>
      </el-card>
    </el-col>
    <el-col :span="3">
      <el-card shadow="always">
        <div slot="header" class="clearfix"  style="font-size: 13px;color: green">
          <span>申请中</span>
        </div>
        <div style="text-align: center;color: red;font-size: 20px" v-if="myFormInfo.apping!=0">
          {{myFormInfo.apping}}
        </div>
        <div style="text-align: center" v-if="myFormInfo.apping==0">
          {{myFormInfo.apping}}
        </div>
      </el-card>
    </el-col>
    <el-col :span="3">
      <el-card shadow="always">
        <div slot="header" class="clearfix"  style="font-size: 13px;color: green">
          <span>已完成</span>
        </div>
        <div style="text-align: center">
          {{myFormInfo.finished}}
        </div>
      </el-card>
    </el-col>
    <el-col :span="3">
      <el-card shadow="always">
        <div slot="header" class="clearfix"  style="font-size: 13px;color: green">
          <span>已中止</span>
        </div>
        <div style="text-align: center">
          {{myFormInfo.suspended}}
        </div>
      </el-card>
    </el-col>
    <el-col :span="3">
      <el-card shadow="always">
        <div slot="header" class="clearfix"  style="font-size: 13px;color: green">
          <span>未审批</span>
        </div>
        <div style="text-align: center;color: red;font-size: 20px" v-if="myFormInfo.agenting!=0">
          {{myFormInfo.agenting}}
        </div>
        <div style="text-align: center" v-if="myFormInfo.agenting==0">
          {{myFormInfo.agenting}}
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import SideMenu from "@/views/inc/SideMenu";
import Tabs from "@/views/inc/Tabs";

export default {
  name: "Index.vue",
  data() {
    return {
      myFormInfo: {
        total: '0',
        apping: '0',
        finished: '0',
        suspended:'0',
        agenting: '0'
      }
    }
  },

  created() {
    this.getMyFormInfo()
  },

  methods: {
    getMyFormInfo() {
      console.log("正在访问我的表单信息")
      this.$axios.get("/pro/pro/myFormInfo").then(res => {
        this.myFormInfo = res.data.data;
      })
      console.log("访问我的表单信息完毕")
    },
  }
}
</script>

<style scoped>

</style>
