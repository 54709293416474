import axios from "axios";
import Element from 'element-ui'
import router from "./router";
//axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = "http://localhost:8081"
// axios.defaults.baseURL = "http://localhost:8081"

const request = axios.create({
  baseURL: '/api/zsjyxy',
  // baseURL: 'http://10.32.1.246:8082/api',
  timeout: 500000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})
request.interceptors.request.use(config => {
  // if(localStorage.getItem("token")!=null){
    config.headers['Authorization'] = localStorage.getItem("token") // 请求头带上token
    console.log("自动带上token")
    console.log(localStorage.getItem("token"))
  // }

  return config
})
request.interceptors.response.use(response => {
    let res = response.data;
    console.log("response")
    console.log(res)
    console.log(response)

    return response
    if (res.code === 200 || res.code === 302 || res.status===200) {
      return response
    } else {
      Element.Message.error(res.msg? res.msg : '系统异常！', {duration: 3 * 1000})
      //Element.Message.error(res.msg)
      return Promise.reject(response.data.msg)
    }
  },
  error => {
    console.log(error)
    if(error.response.data) {
      error.message = error.response.data.msg
    }
    if(error.response.status === 401) {
      router.push("/login")
    }
    Element.Message.error(error.message, {duration: 3 * 1000})
    return Promise.reject(error)
  }
)
export default request
