<template>
  <el-container>
    <el-aside width="200px">
      <SideMenu></SideMenu>
    </el-aside>
    <el-container>
      <el-header style="height: 55px;">
        <Strong>网上登记审批系统</Strong>
        <div class="header-avatar block">
          <el-avatar class="el-avatar" size="medium" :src="userInfo.avatar"></el-avatar>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{userInfo.username}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editHandle">个人信息</el-dropdown-item>
              <el-dropdown-item :underline="false">
                <router-link :to="{name: 'UserCenter'}">修改密码</router-link>
              </el-dropdown-item>
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-link href="http://www.zsjyxy.cn/">舟山教育学院</el-link>
        </div>
      </el-header>
      <el-main>

        <Tabs></Tabs>
        <div style="margin: 0 15px;">
          <router-view></router-view>
        </div>
      </el-main>

      <!--新增对话框-->
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose">

        <el-form :model="editForm" ref="editForm">
          <el-form-item label="用户名" prop="username" label-width="100px">
            <el-input v-model="editForm.username" autocomplete="off" disabled=true></el-input>
            <el-alert
              title="初始密码为888888"
              :closable="false"
              type="info"
              style="line-height: 12px;"
            ></el-alert>
          </el-form-item>

          <el-form-item label="部门" prop="departmentId" label-width="100px">
            <el-select v-model="editForm.departmentId" placeholder="请选择部门" disabled=true>
              <template v-for="item in departments">
                <el-option :label="item.name" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="职位" prop="postId" label-width="100px">
            <el-select v-model="editForm.postId" placeholder="请选择职位"  disabled=true>
              <template v-for="item in posts">
                <el-option :label="item.postname" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="邮箱"  prop="email" label-width="100px">
            <el-input v-model="editForm.email" autocomplete="off" disabled=true></el-input>
          </el-form-item>
          <el-form-item label="手机号"  prop="phone" label-width="100px">
            <el-input v-model="editForm.phone" autocomplete="off" disabled=true></el-input>
          </el-form-item>

          <el-form-item label="状态"  prop="statu" label-width="100px">
            <el-radio-group v-model="editForm.statu"  disabled=true>
              <el-radio :label="0">禁用</el-radio>
              <el-radio :label="1">正常</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="角色"  prop="sysRoles" label-width="100px">
            <el-tag type="info" v-for="item in editForm.sysRoles">{{item.name}}</el-tag>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="resetForm('editForm')">关闭</el-button>
        </div>
      </el-dialog>
    </el-container>
  </el-container>

</template>

<script>

import SideMenu from "./inc/SideMenu";
import Tabs from "./inc/Tabs";
import store from "@/store";

export default {
  name: "Home.vue",
  components: {
    SideMenu,Tabs

  },
  data() {
    return {
      userInfo: {
        id: '',
        username: '',
        avatar: ''
      },
      dialogVisible: false,
      editForm: '',
      posts: '',
      departments: '',
    }
  },

  created() {
    this.getUserInfo()
    this.$axios.get("/sys/department/simplelist").then(res => {
      this.departments = res.data.data
      console.log("departments")
      console.log(this.departments)
    })

    this.$axios.get("/sys/post/list").then(res => {
      this.posts = res.data.data
      console.log("posts")
      console.log(this.posts)
    })
  },

  methods: {
    getUserInfo() {
      console.log("正在访问用户信息")
      this.$axios.get("/sys/userInfo").then(res => {
        this.userInfo = res.data.data;
      })
      this.$store.commit("setUserInfo",this.userInfo)
      console.log("访问用户信息完毕")
    },
    logout() {
      this.$axios.post("/logout").then(res => {
        console.log(res.data.data)
        localStorage.clear()
        sessionStorage.clear()
        this.$store.commit("resetState")

        console.log("退出成功")
        this.$router.push("/login")
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    editHandle() {
      this.$axios.get('/sys/user/myInfo').then(res => {
        this.editForm = res.data.data
        this.dialogVisible = true
      })
    },
    handleClose() {
      this.resetForm('editForm')
    },
  }
}

</script>


<style scoped>
.el-container {
  padding: 0;
  margin: 0;
  height: 100%;
}
.header-avatar {
  float: right;
  width: 210px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-header {
  background-color: #17B3A3;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-aside {
  background-color: #D3DCE6;
  color: #333;
  line-height: 200px;
}
.el-main {
  color: #333;
  padding: 0;
}
a {
  text-decoration: none;
}
</style>
